.center-container {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .centered-elements {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .headerH1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .defaultH2 {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  
  .textExtractContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 100%;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }