
/* display: flex; */
.newsCard {

  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-radius: 5px;

  width: 350px;

  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  height: auto; 
}

.dd {
  width: 300px; 
  height: 200px; 
  overflow: hidden; 
}
/*  */
.newsCardTitle {
  max-width: 330px; /* Maximum width of the title */
  max-height: 40px; /* Adjust this to fit two lines of text */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: normal; /* Allow text to wrap to next line */
  line-height: 20px; /* Adjust line height for better readability */
  display: -webkit-box; /* Use webkit box model */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Vertical box orientation */
  text-decoration: underline;
}


.newsCard:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.newsCard h2 {
  font-size: 18px;
  margin-top: 0;
}

.newsCard img {

  margin-top: 20px;
  margin-bottom: 10px;
  width: 330px;
  height: 280px;
  border-radius: 5px;
}

.newsCardDescription {
  width: 320px; /* Adjust the width as needed */
  height: 50px; /* Adjust or remove height for automatic height calculation */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Display 5 lines of text */
  -webkit-box-orient: vertical;
  line-height: 1.5; 

}

.newsCard p {
  margin: 10px 0;
}

.tagNVDA {
  background-color: green;
}

.tagGOOG {
  background-color: orange;
}

.tagAAPL {
  background-color: red;
}

.tagMSFT {
  background-color: grey;
}


.tag {
  height: 24px;
  /* width: 51px; */
  display: inline-block;
  padding: 2px 10px;
  border-radius: 6px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}



.footer {
  display: flex;

  margin-top: auto;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  font: bold;
}



.footerSomewhat-Bullish {
  color: #FF7F00;
}

.footerBullish {
  color: #12B812;
}

.footerNeutral {
  color: #808080;
}

.footerSomewhat-Bearish { 
  color: #FF7F00;
}

.footerBearish {
  color: #FF0000;
}

.date {
  color: grey;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: normal;
}






