.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.login-container {
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
}

.login-container h3 {
  text-align: center;
}

.login-form label {
  display: block;
  margin-bottom: 10px;
}

.login-form input[type="text"],
.login-form input[type="email"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #45a049;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
}

.signin-button,
.signup-button {
  width: 140px;
  padding: 10px;
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-button:hover,
.signup-button:hover {
  background-color: #999;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin-top: 20px;
  /* Add some top margin */
}

.contact-button,
.signup-button {
  flex: 1;
  /* Distribute equal width to both buttons */
  margin-right: 10px;
  /* Add right margin to create spacing between buttons */
  padding: 10px;
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-button:last-child {
  margin-right: 0;
  /* Remove right margin from the last button */
}