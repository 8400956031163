.financial-products {
  display: flex;
  gap: 5px;
}

.tag {
  background-color: #6200ea; 
  border-radius: 20px; 
  color: #ffffff; 
  padding: 5px 10px; 
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tag:hover {
  background-color: #4b00b5; 
}

.close-icon {
  margin-left: 5px;
  cursor: pointer;
}
