
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    margin: 0 auto;
    
  }
  
  ::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px) { 
    .cardContainer {
      grid-template-columns: 1fr; 
      padding-left: 0px; 
    }
  }
  
  