/* FinanceAIPage.module.css */

select, input[type="text"], input[type="range"] {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

button {
  padding: 10px 15px;
  background-color: #667eea;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


