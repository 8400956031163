.search {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.searchInputs {
  position: relative;
  display: flex;
  align-items: center;
  width: 832px;
  padding-right: 40px;
  border-radius: 8px;
  border: 3px solid var(--colors-primary, #6e41e2);
  background: white;
}

.searchInputbar {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 16px;
  border-radius: 8px;
  background: white;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: white;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.dataResult {
  width: 832px;
  margin-top: 10px;
  background: white;
  overflow-y: auto;
  max-height: 300px;
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08),
    0px 4px 4px 0px rgba(51, 51, 51, 0.04);
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.dataItem:hover {
  background-color: #f0f0f0;
}

.dataItem p {
  margin: 0;
}
