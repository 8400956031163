
.filterBar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0;
  font-size: 16px;
  height: 100px;
  padding-left: 60px;
}

.filterBar select {
  margin-right: 10px;

  /* padding: 8px 12px; */
  border-radius: 5px;
  border: 1px solid #ddd;
  color: gray;
  height: 52px;
  width: 250px;
  font-family: Arial, sans-serif;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.aiInsight {
  background-color: #6E41E2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  height: 56px;
}

.aiInsight:hover {
  background-color: #4b00b5;
}

.searchContainer {
  margin-left: 60px;
  /* margin-top: 10px; */
  /* display: flex;*/
  align-items: center;
  /* height: 36px; */
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-size: 20px; 
  border-radius: 5px;
}
input[type="text"] {
  padding-right: 30px; 
  font-size: 18px; 
}

.searchIcon {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%); 
  cursor: pointer;
} 


.modal_backdrop {
  position: relative;
  top: 0;
  left: 60px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; 
  justify-content: center; 
  z-index: 1000;
  border-radius: 4px;
  width: 95.3%;
}

.modal_content {
  font-size: 16px;
  font-family: 'Roboto', sans-serif; 
  color: #6200ea;
  background-color: #fff;
  padding: 20px;
  padding-left: 60px;
  border-radius: 5px;
  width: 100%; 
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  list-style-position: none; 
}

.modal_content ul{
  list-style-type: none; 
  padding-left: 0;
  margin-left: 0;
}
.modal_content li{
  font-weight: bold;
}


