
.html {
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.5;
  color: #333;
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  display: block;
}

.container {
  display: flex;
  flex-direction: column;
}

.mainArea {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sidebar {
  flex: 0 0 20rem; 
}

.mainContent {
  flex: 1; 
  height: 100vh;
}
