.inline-p {
  display: inline-block;
  margin-right: 10px;  /* Optional: adds some spacing between the two paragraphs */
}
.tags-container {
  width: 450px; 
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.tag-filter {
  
  font-weight: bold;
  margin-bottom: 15px;
}

.close {
  margin: 5px;
  background-color: #660be6;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
}

.empty-shadow-box {
  width: 100%; 
  height: 40px; 
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
}

.card {
  
  margin-top: 20px;
}

button {
  padding: 5px;
  background-color: #660be6;
  border-radius: 10%;
  margin: 5px;
}