.moodContainer {
  border-radius: 10px;
  display: flex;
  align-items: center;

  padding: 20px 0;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
  font-size: 13px;
  color: white;
  font-weight: bold;
  margin-left: 10px;
  padding-left: 50px;  

}

.moodContainer > div {
  flex: 1;
  text-align: center;

  height: 20px;
}

.moodContainer > div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.moodContainer > div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bullish {
  background-color: #12B812;
}

.somewhatBullish {
  background-color: #32A4F8;
}

.neutral {
  background-color: #808080;
}

.somewhatBearish {
  background-color: #FF7F00;
  ;
}

.bearish {
  background-color: #FF0000;

}



