.center-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.centeredElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px; /* 可根据需要调整间距 */
  gap: 10px;
}


.wrap {
  margin: 40px auto;
  width: 500px;
}

.method-fieldset {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
}

.method-fieldset .title {
  position: absolute;
  top: -13px;
  left: 10%;
  line-height: 2em;
  padding: 0 1em;
  background-color: #fff;
}

.upload-box {
  border: 2px dashed #999;
  padding: 60px;
  display: inline-block;
}

.dropYourFileText {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 10px;
}

.dropAndSpacing {
  margin-top: 30px;
  margin-bottom: 40px;
}

.orSpacing {
  margin-bottom: 40px;
}

.textDisplayEditorContainer {
  position: relative;
  width: 37.5rem;
  height: 18.75rem;
  flex-shrink: 0;
  padding: 20px;
  border: 4px dashed #d9d9d9;
  border-radius: 8px;
  background-color: #fdfdfe;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 30px 0;
  overflow-y: auto;

}

.chooseFileDiv {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cFInnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--colors-primary, #6e41e2);
  border-radius: 0.25rem;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  padding: 5px;
}

.uploadedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: 300px;
}
.maxFileSizeTextWrapper {
  position: relative;
}

.maxFileSizeText {
  position: absolute;
  top: 1.8rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: Roboto;
  font-size: 1rem;
}
.fileNameCenter {
  color: var(--colors-gray-k, #6b6b6b);
  text-align: center;
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-decoration-line: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.startTextExtractorButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.startTextExtractorButton {
  background-color: #6e41e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
}
.startTextExtractorButton:hover {
  background-color: #5a34c1;
}
.checkIcon {
  width: 1rem;
  height: 1rem;
}

.uploadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.uploadingText {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.wrap {
  margin: 50px auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadingGif {
  width: 12.375rem;
  height: 12.375rem;
  fill: var(--colors-primary);
  background: url("../../asset/loading.gif") center / cover no-repeat;
}

.tableDisplayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  width: 600px;
}

.dashboardText {
  width: 100%;
  max-height: 25rem;
  overflow-y: auto;
  background-color: #fdfdfe;
  border: 4px dashed #d9d9d9;
  padding: 20px;
  border-radius: 8px;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
}



.dataTable td {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
}

.dataTable tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.filenameContainer{
  padding: 0.625rem 1.25rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 30px auto;
  transition: background-color 0.3s;
}

.downloadButtonContainer {
  display: flex;
  width: 14rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

}

.downloadButtonWrap{
  padding: 0.625rem 1.25rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.25rem;
  border: 2px solid var(--colors-primary);
  cursor: pointer;
  transition: background-color 0.3s;

}


.downloadIcon {
  width: 1.125rem;
  height: 1.125rem;
}
.downloadText,
.fileSizeText {
  color: var(--colors-primary);
  text-align: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.filenameContainer {
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top: 2rem; 
}

.filenameInput {
  padding: 0;
  border: none;
  outline: none;
  text-decoration: underline;
  background-color: transparent;
  font-size: 1.25rem;
  color: var(--colors-gray-k, #6b6b6b);
  font-family: Roboto;
  font-weight: 400;
  line-height: 1.75rem;
  text-align: center;
}

.editIcon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.editIcon:hover {
  filter: brightness(80%);
}
