.container {
    display: flex;
    flex-direction: column;
  }
  
  /* Horizontal layout managing sidebar and main content */
  .mainArea {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .sidebar {
    flex: 0 0 15rem;
  }
  
  .detectDocumentTextPageArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 15rem;
  }
  
  .dashboardText {
    align-self: flex-start;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
  }

  .defaultH1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .defaultH2 {
    color: #000;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .dashboardLink {
    color: var(--colors-primary-active, #472C8A);
    margin-right: 0.625rem;
  }
  
  .detectorLink {
    color: var(--colors-primary-active, black);
    margin-right: 0.625rem;
  }
  
  .dashboardText {
    align-self: flex-start; 
    margin-top: 0.625rem;  
    margin-left: 0.625rem; 
  }

  .detectDocumentTextPageArea {
    flex: 1;
    display: flex;
    flex-direction: column; 
    align-items: center;
    min-height: 15rem;
  }

  .detectDocumentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 100%;
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  