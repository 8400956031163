

.centeredElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px; /* 可根据需要调整间距 */
  gap: 10px;
}

.downloadButtonContainer {
  display: flex;
  gap: 10px; /* 添加按钮之间的间隔 */
}

.wrap {
  margin: 50px auto;
  width: 500px;
}

.method-fieldset {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
}

.method-fieldset .title {
  position: absolute;
  top: -13px;
  left: 10%;
  line-height: 2em;
  padding: 0 1em;
  background-color: #fff;
}

.upload-box {
  border: 2px dashed #999;
  padding: 60px;
  display: inline-block;
}

.dropYourFileText {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 10px;
}

.dropAndSpacing {
  margin-top: 30px;
  margin-bottom: 40px;
}

.orSpacing {
  margin-bottom: 40px;
}

.textDisplayEditorContainer {
  position: relative;
  width: 37.5rem;
  height: 18.75rem;
  flex-shrink: 0;
  padding: 20px;
  border: 4px dashed #d9d9d9;
  border-radius: 8px;
  background-color: #fdfdfe;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 30px 0;
  overflow-y: auto;
}

.chooseFileDiv {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cFInnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--colors-primary, #6e41e2);
  border-radius: 0.25rem;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  padding: 5px;
}


.uploadedContainer {
  display: flex;
  justify-content: center; 
  align-items: center;     
  width: 100%;            
  height: 100%; 
  overflow-y: auto; 
  max-height: 300px; 
}

.fileNameCenter {
  color: var(--colors-gray-k, #6B6B6B);
  text-align: center;
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-decoration-line: underline;
  display: flex;
  flex-direction: row; 
  align-items: center;
  gap: 0.5rem;
}
.checkIcon {
  width: 1rem;
  height: 1rem;
}

.uploadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.uploadingText {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.wrap {
  margin: 50px auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.downloadButtonContainer {
  margin-top: 20px;  
  display: flex;
  justify-content: center;
  width: 100%;
}


.uploadingGif {
  width: 12.375rem;
  height: 12.375rem;
  fill: var(--colors-primary);
  background: url("../../asset/loading.gif") center / cover no-repeat;
}

