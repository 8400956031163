.search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
  }
  
  .searchInputs {
    position: relative;
    display: flex;
    align-items: center;
    width: 832px;
    padding-right: 40px;
    border-radius: 8px;
    border: 3px solid var(--colors-primary, #6e41e2);
    background: white;
  }
  
  .searchInputbar {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 16px;
    border-radius: 8px;
    background: white;
  }
  
  .searchIcon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    background: white;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .dataResult {
    width: 832px;
    margin-top: 10px;
    background: white;
    overflow-y: auto;
    max-height: 300px;
    box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08),
      0px 4px 4px 0px rgba(51, 51, 51, 0.04);
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  .dataItem:hover {
    background-color: #f0f0f0;
  }
  
  .dataItem p {
    margin: 0;
  }
  
  .title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
  }

  .subtitle {
    font-size: 30x;
    font-weight: bold;
    margin-top: 10px;
  }

  .card {
    padding: 10px;            /* 内部的填充空间 */
    border-radius: 15px;       /* 边角的圆滑程度 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 阴影效果 */
    width: 200px;             /* 卡片的宽度 */
    height: 150px;
    margin: 10px;             /* 外部边距 */
  }
  
  .card h4 {
    margin-top: 0;            /* 标题顶部的边距 */
    color: black;
    font-weight: bold;
  }
  
  .card p {
    color: gray;              /* 段落文字颜色 */
  }

  .cardContainer {
    display: flex;
    justify-content: space-around; /* 或者使用 space-between */
  }

  .chartContainer {
    width: 500px;
    height: auto;
  }

  .cashFlowcardContainer {
    display: flex;
    justify-content: space-around; /* 或使用 space-between */
    flex-wrap: wrap; /* 允许在必要时换行 */
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:nth-child(odd) {
    background-color: #fff; /* 白色背景用于奇数行 */
  }
  
  tr:nth-child(even) {
    background-color: #f4f4f4; /* 灰色背景用于偶数行 */
  }

  .cardContainer2 {
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
  }

.flex_container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
}

.flex_item {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically, if needed */
  width: fit-content; /* Shrink the width to the content size */
}

.flex_item_right {
  margin-left: -60px; /* Reduce this value to move closer */
}
.custom_legend {
  display: grid;
  grid-template-columns: auto; /* Single column layout */
  row-gap: 10px; /* Space between each row */
}

.legend_item {
  display: grid;
  grid-template-columns: 15px auto auto; /* Columns for color, x, and y values */
  align-items: center;
  gap: 10px; /* Space between elements */
}

.legend_color {
  width: 15px;
  height: 15px;
  border-radius: 50%; /* Makes it circular */
}

.legend_text {
  font-weight: bold;
}

.legend_text_color {
  color: #979699; /* Sets the text color to #979699 */
}
.titleWrapper {
  display: flex;
  align-items: flex-end;
}

.button_style {
  margin-left: 600px;
}

.linechart_style {
  margin-top: -150px;
  margin-left: -120px;
}