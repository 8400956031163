/* SearchBar.css */
.searchBar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .searchbar input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .searchBar button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .searchBar button:hover {
    background-color: #45a049;
  }
  .container {
    /* width: 100%; */
    max-width: 1200px;

    padding-top: 10px;

    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;



    margin-right: 20px;
    margin-left: 60px;

}
.nail {
    margin-bottom: 32px;
    margin-right: 32px;
}
.footer {
    margin: 50px 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.root {
  margin-top: 5px;
}
.layout-wrapper {
  margin-top: 100px;
  margin-left: 20px;

}
