.categories-container {
    display: inline-flex;
    align-items: flex-start;
    gap: 51px;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.category-icon {
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
}

.category-label {
  font-size: 14px;
  text-align: center;
  color: #333;
}
