
.input-container {
  text-align: center;
  margin: 20px;
}

.inputText {
  padding: 10px;
  width: 100%; 
  height: 40px; 
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 18px; 
  
}

.button:hover {
  background-color: #0056b3;
}

.largerTextarea {
  width: 50%;
  height: 300px; 
  resize: vertical; 
  font-size: 26px;
  font-family: Arial, sans-serif;
}

.customButton {

  color: #fff;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}