.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  width: 25.125rem;
  height: 12.5rem;
  flex-shrink: 0;
  justify-content: flex-start;
}

.card {
  background-image: url("../../../../asset/ToolCard.png");
  background-size: cover;
  width: 25.125rem;
  height: 12.5rem;
  border-radius: 0.5rem;
}

.cardContent {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3125rem;
  margin-top: 1.25rem;
}

.card:hover {
  transform: translateY(-0.3125rem);
}

.cardCategory {
  display: flex;
  padding: 0.0625rem 0.5rem; /* <-- changed from 1px 8px */
  justify-content: center;
  align-items: center;
  color: var(--colors-gray-l, #f1f1f1);
}

.card {
  background-image: url("../../../../asset/ToolCard.png");
  background-size: cover;
  width: 402px;
  height: 200px;
  border-radius: 8px;
}

.cardContent {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-top: 20px;
}

.card:hover {
  transform: translateY(-5px);
}

.cardCategory {
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  color: var(--colors-gray-l, #f1f1f1);
  border-radius: 100px;
  background: #472c8a;
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08),
    0px 4px 4px 0px rgba(51, 51, 51, 0.04);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */
}

.cardToolName {
  color: var(--colors-primary-active, #472c8a);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.cardDescription {
  font-size: 0.9em;
  color: #666;
  text-align:left;
}

.cardButton {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 2px solid var(--colors-primary-active, #472c8a);
  color: var(--colors-primary, #6e41e2);
  background-color: transparent;
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.2s, border-color 0.3s, color 0.3s; /* Add transitions */
}

.cardButton:hover {
  background-color: var(--colors-primary, #6e41e2); 
  color: white; 
  border-color: var(--colors-primary-active, #472c8a);
}

.cardButton:active {
  transform: scale(0.95); /* Give the button a "pressed" effect when clicked */
  background-color: var(--colors-primary-active, #472c8a); 
}
.userCreditContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 32px;
}

.CreditIconAndNumber {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cardIcon {
  width: 16px;
  height: 16px;
}

.cardCost {
  color: var(--colors-primary-active, #472c8a);
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 800px) {
  .cardContainer {
    grid-template-columns: 1fr;
    padding-left: 0px; /*If you need to further push the card to the left, you can adjust this value*/
  }
}