.survey-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.question {
  margin-bottom: 20px;
}

label {
  /* display: block; */
  margin-bottom: 10px;
  font-weight: bold;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}











