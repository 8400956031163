.question-p {
    float: left;
    margin-right: 120px;
    color: #4B0082; 
    font-weight: bold; 
}

.question-name {
    width: 320px;
    float: left;
    font-weight: normal;
    font-size: 0.9em;
}
